.cover {
    position: relative;
    background-color: $color-light;
    padding: 130px 0 60px;
    margin-bottom: 50px;

    &__image-wrapper {
        display: none;
    }

    &__title {
        margin: 0 0 15px;
        padding-left: 35px;
        position: relative;

        &:before {
            @include position(absolute, $top: 50%, $left: 0);
            transform: translateY(-50%);
            @include size(20px);
            content: "";
            background-color: $color-fifth;
            border-radius: 50%;
            z-index: 0;
        }
    }

    &__lead-wrapper {
        font-family: $font-family;
        font-size: toRem(18);
        font-weight: 500;
        line-height: toRem(25);
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .cover {
        padding: 100px 0 60px;
    }
}

// 960
@media screen and (max-width: $medium) {
    .cover {
        padding: 80px 0 40px;
    }
}


// 640
@media screen and (max-width: $small) {
    .cover {
        padding: 60px 0 30px;

        &__title {
            font-size: toRem(30);
            line-height: toRem(35);
        }
    }
}
