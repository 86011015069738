.front-page {
    position: relative;

    &:before {
        @include position(absolute, $top: 0, $left: 0);
        @include size(100%, 650px);
        content: "";
        background-color: $color-light;
        z-index: -1;
    }
}

//======================================================================================================
// Qui sommes-nous ?
//======================================================================================================

.presentation {
    padding-top: 140px;
    margin-bottom: 100px;

    &__container {
        @include flex($wrap: wrap, $justifyContent: space-between, $alignItems: flex-end);
        background-color: $color-white;
        box-shadow: $shadow;
        border-radius: 20px;
        padding: 40px;
    }

    &__title {
        @include flex($alignItems: center);
        gap: 20px;
        font-size: toRem(25);
        font-weight: 400;
        line-height: toRem(30);
        text-transform: uppercase;
        margin: 0;
        padding: 0;
        width: 100%;

        span {
            @include size(20px);
            border-radius: 50%;
            border: 3px solid $color-gray;
        }
    }

    &__intro {
        font-size: toRem(18);
        font-weight: 400;
        line-height: toRem(25); 
        width: 850px;
        margin-top: 15px;

        strong {
            font-weight: 700;
        }
    }

    &__fleche {
        @include flex($alignItems: center, $justifyContent: center);
        @include size(40px);
        background-color: $color-gray;
        border: 2px solid $color-gray;
        box-sizing: border-box;
        border-radius: 50%;
        transition: all $duration ease-in-out;
        
        svg {
            fill: $color-white;
            @include size(20px);
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            background-color: transparent;

            svg {
                fill: $color-dark;
            }
        }
    }

    &__buttons {
        @include flex($alignItems: center, $justifyContent: space-between);
        margin: 30px 0 0;
    }

    &__button {
        @include flex($alignItems: center, $justifyContent: center);
        gap: 10px;
        @include size(370px, 60px);
        border-radius: 15px;
        font-size: toRem(20);
        font-weight: 700;
        line-height: toRem(20);
        letter-spacing: 0.05em;
        text-transform: uppercase;
        border: 2px solid;
        transition: all $duration ease-in-out;

        span {
            @include size(15px);
            border-radius: 50%;
            border: 3px solid;
            transition: all $duration ease-in-out;
        }

        &:nth-child(1) {
            background-color: $color-third;
            border-color: $color-third;
            color: $color-dark;

            span {
                border-color: $color-dark;
            }
        }

        &:nth-child(2) {
            background-color: $color-main;
            border-color: $color-main;
            color: $color-dark;

            span {
                border-color: $color-dark;
            }
        }

        &:nth-child(3) {
            background-color: $color-gray;
            border-color: $color-gray;
            color: $color-white;

            span {
                border-color: $color-white;
            }

            &:hover, &:focus {
                color: $color-dark;

                span {
                    border-color: $color-dark;
                }
            }
        }

        &:hover, &:focus {
            background-color: transparent;
        }
    }
}

//======================================================================================================
// Emploi
//======================================================================================================

.job {
    &__cards {
        @include flex($alignItems: center, $justifyContent: space-between);
    }

    &__card {
        @include size(570px, 300px);
        position: relative;

        &:before {
            @include position(absolute, $top: 0, $left: 0);
            @include size(100%);
            content: "";
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%);
            border-radius: 20px;
            z-index: 1;
        }

        &:after {
            @include position(absolute, $bottom: 45px, $left: 25px);
            content: "";
            @include size(20px);
            border-radius: 50%;
            transition: background-color 0.5s ease-in-out;
            z-index: 1;
        }

        &--consult::after {
            background-color: $color-third;
        }

        &--deposit::after {
            background-color: $color-main;
        }

        &:hover, &:focus {
            &:after {
                background-color: $color-white;
            }

            .job {
                &__image::before {
                    @include size(595px);
                    left: -230px;
                    bottom: -205px
                }

                &__fleche {
                    left: 65px;
                    opacity: 1;
                    pointer-events: all;
                }

                &__content {
                    transform: translateY(-15px);
                }

                &__title {
                    color: $color-dark;
                    text-shadow: unset;
                }

                &__intro {
                    opacity: 1;
                    pointer-events: all;
                    color: $color-dark;
                }
            }
        }
    }

    &__image {
        @include size(100%);
        border-radius: 20px;
        overflow: hidden;
        position: relative;

        &:before {
            @include position(absolute, $bottom: 55px, $left: 35px);
            content: "";
            @include size(0);
            border-radius: 50%;
            transition: all 0.5s ease-in-out;
            z-index: 1;
        }

        &--consult::before {
            background-color: $color-third;
        }

        &--deposit::before {
            background-color: $color-main;
        }

        img {
            object-fit: cover;
            @include size(100%);
            border-radius: 20px;
        }
    }

    &__content {
        @include position(absolute, $bottom: 25px, $left: 65px);
        transform: translateY(50px);
        transition: all 0.5s ease-in-out;
        z-index: 1;
    }

    &__fleche {
        @include position(absolute, $top: 115px, $left: -20px);
        @include size(20px);
        opacity: 0;
        pointer-events: none;
        transition: all 0.5s ease;
        z-index: 1;
    }

    &__title {
        font-size: toRem(25);
        font-weight: 400;
        line-height: toRem(30);
        text-transform: uppercase;
        color: $color-white;
        text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.50);
        margin: 0;
        padding: 0;
        transition: all 0.5s ease-in-out;
    }

    &__intro {
        font-size: toRem(18);
        font-weight: 400;
        line-height: toRem(25);
        color: $color-white;
        max-width: 260px;
        opacity: 0;
        pointer-events: none;
        transition: all 0.5s ease-in-out;
    }
}

//======================================================================================================
// Actualités et agenda
//======================================================================================================

.acag {
    margin-top: 120px;
    padding-bottom: 80px;
    position: relative;

    &:before {
        @include position(absolute, $bottom: 0, $left: 0);
        @include size(100%, 370px);
        content: "";
        background-color: $color-light;
        z-index: -1;
    }

    &__title {
        @include flex($alignItems: center);
        gap: 15px;
        font-size: toRem(35);
        font-weight: 400;
        line-height: toRem(40); 
        text-transform: uppercase;
        margin: 0 0 30px;
        padding: 0;

        span {
            @include size(20px);
            border-radius: 50%;

            &:first-child {
                background-color: $color-fourth;
            }

            &:last-child {
                background-color: $color-second;
            }
        }
    }

    &__item {
        @include size(370px, 310px!important);
        overflow: hidden;
        border-radius: 20px;
        position: relative;

        &:before {
            @include position(absolute, $top: 0, $left: 0);
            @include size(100%);
            content: "";
            background: rgba(0, 0, 0, 0.30);
            border-radius: 20px;
            z-index: 0;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            &:before {
                background: rgba(0, 0, 0, 0.60);
            }

            .acag {
                &__fleche {
                    @include position(absolute, $top: -3px, $right: -3px);
                }

                &__date p span {
                    border-color: $color-second;
                    background-color: $color-second;
                }

                &__intro {
                    max-height: 100px;
                    opacity: 1;
                    margin: 10px 0 0;
                }
            }
        }
    }

    &__image {
        @include size(100%);
        border-radius: 20px;

        img {
            object-fit: cover;
            @include size(100%);
            border-radius: 20px;
        }

        &--notimg {
            @include size(370px, 310px);
            border-radius: 20px;

            &-event {
                background: url('/wp-content/themes/noyau/assets/images/icon-events.png') no-repeat $color-light center;
            }

            &-news {
                background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-light center;
            }
        }
    }

    &__fleche {
        @include flex($alignItems: center, $justifyContent: center);
        @include size(40px);
        border-radius: 50%;
        @include position(absolute, $top: -30px, $right: -30px);
        pointer-events: none;
        transition: all $duration ease-in-out;

        svg {
            @include size(20px);
            margin: 3px 3px 0 0;
        }

        &--post {
            background-color: $color-fourth;

            svg {
                fill: $color-dark;
            }
        }

        &--event {
            background-color: $color-second;

            svg {
                fill: $color-white;
            }
        }
    }

    &__content {
        @include position(absolute, $left: 20px, $bottom: 20px);
        width: calc(100% - 40px);
    }

    &__date {
        @include flex($alignItems: center);
        gap: 10px;
        font-size: toRem(30);
        font-weight: 700;
        line-height: toRem(35);
        text-transform: uppercase;
        text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.70);
        color: $color-white;

        p {
            @include flex($alignItems: center);
            gap: 10px;
            
            span {
                @include size(10px);
                min-width: 10px;
                display: flex;
                border: 1px solid $color-white;
                box-sizing: border-box;
                border-radius: 50%;
                transition: all $duration ease-in-out;
            }
        }
    }

    &__title--small {
        font-family: $font-family;
        font-size: toRem(22);
        font-weight: 700;
        line-height: toRem(25);
        text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.70);
        color: $color-white;
        margin: 5px 0 0;
        padding: 0;
    }

    &__intro {
        font-size: toRem(16);
        font-style: normal;
        font-weight: 400;
        line-height: toRem(25);
        text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.70);
        color: $color-white;
        margin: 0;
        max-height: 0;
        opacity: 0;
        transition: all $duration ease-in-out;
    }

    &__bottom {
        @include flex($justifyContent: flex-end, $alignItems: center);
        gap: 30px;
        margin: 30px 0 0;
    }

    &__navigation {
        display: flex; 
        gap: 10px;
        z-index: 0;

        &--prev, &--next {
            // Enlever style par défaut des flèches
            @include size(50px!important);
            @include position(relative!important, $top: unset!important, $left: unset!important, $bottom: unset!important, $right: unset!important);
            border: 2px solid $color-fifth;
            background-color: $color-dark--rgba;
            border-radius: 15px;
            margin: 0!important;
            transition: all $duration ease-in-out;
            cursor: pointer;
            z-index: 2!important;

            &:after {
                display: none;
            }

            svg {
                fill: $color-dark;
                @include size(100%);
                transition: all $duration ease-in-out;
            }

            &:hover, &:focus {
                background-color: transparent;
            }

        }

        &.disable {
            display: none;
        }
    }

    &__buttons {
        @include flex($alignItems: center);
        gap: 30px;
    }

    &__button {
        gap: 10px;
        margin: 0;
        padding: 0;
        height: 50px;

        svg {
            @include size(20px);
        }

        &:first-child {
            width: 289px
        }

        &:last-child {
            width: 278px;
            background-color: $color-second;
            border-color: $color-second;
            color: $color-white;

            svg {
                fill: $color-white;
            }

            &:hover, &:focus {
                background-color: transparent;
                color: $color-dark;

                svg {
                    fill: $color-dark;
                }
            }
        }
    }
}

//======================================================================================================
// Social - Newsletter & Social Wall
//======================================================================================================

.social {
    margin: 120px 0 0;
    position: relative;

    &:before {
        @include position(absolute, $bottom: 0, $left: 0);
        @include size(100%, 115px);
        content: "";
        background-color: $color-light;
        z-index: -1;
    }

    &__title {
        @include flex($alignItems: center);
        gap: 15px;
        font-size: toRem(35);
        font-weight: 400;
        line-height: toRem(40); 
        text-transform: uppercase;
        margin: 0;
        padding: 0;

        span {
            @include size(20px);
            border-radius: 50%;
            background-color: $color-gray;
        }
    }

    &__content {
        @include flex($justifyContent: space-between);
        margin-top: 30px
    }

    &__newsletter {
        @include size(270px, 380px);
        @include flex($direction: column);
        border-radius: 20px;
        background-color: $color-gray;
        padding: 40px 30px;

        h3 {
            @include flex($alignItems: center);
            gap: 10px;
            font-family: $font-family;
            font-size: toRem(22);
            font-weight: 700;
            line-height: toRem(25); 
            color: $color-white;
            margin: 0;
            padding: 0;

            span {
                @include size(10px);
                border-radius: 50%;
                border: 1px solid $color-white;
                box-sizing: content-box;
            }
        }

        p {
            font-size: toRem(14);
            font-weight: 400;
            line-height: toRem(20);
            margin: 20px 0 30px;
            color: $color-white;
        }
    }

    &__networks {
        @include flex($alignItems: center);
        gap: 10px;
        margin: 0 0 30px;
    }

    &__network {
        @include size(50px);
        background-color: rgba($color-dark, 0.2);
        border-radius: 15px;
        transition: all $duration ease-in-out;

        svg {
            @include size(100%);
            fill: $color-white;
        }

        &:hover, &:focus {
            background-color: rgba($color-dark, 0.4);
        }
    }

    &__button {
        @include flex($alignItems: center);
        gap: 10px;
        background-color: $color-white;
        border-color: $color-white;
        margin: 0;
        padding: 0;
        height: 50px;

        svg {
            @include size(20px); 
        }

        &:hover, &:focus {
            background-color: transparent;
            border-color: $color-white;
            color: $color-white;

            svg {
                fill: $color-white;
            }
        }
    }

    #ff-stream-1 {
        width: 870px;
        padding: 0;
        min-height: 380px!important;
        background: transparent!important;
        z-index: 0;

        .ff-header, .ff-nickname, .ff-timestamp, .ff-loadmore-wrapper, .ff-item-meta, .ff-carousel-icon {
            display: none;
        }

        .picture-item__inner {
            background: transparent!important;
            box-shadow: 0 0 0 0 rgba(0,0,0,0)!important;
        }

        .ff-stream-wrapper {
            display: flex;
            margin: 0;
            padding: 0;

            .ff-item {
                width: 270px!important;
                height: 380px;
                border-radius: 20px;
                box-shadow: $shadow;
                transition: box-shadow $duration ease-in-out!important;
                position: relative;

                &:before {
                    @include position(absolute, $top: 0px, $right: 0px);
                    content: "";
                    background-image: url(/wp-content/themes/mission-locale-vignoble-nantais/assets/src/images/sprite-images/Pictos/ic_facebook_sw.svg);
                    background-size: 37px;
                    @include size(37px);
                    z-index: 2;
                }
                
                &:nth-child(1) {
                    transform: unset!important;
                }

                &:nth-child(2) {
                    transform: translateX(300px)!important;
                }

                &:nth-child(3) {
                    transform: translateX(600px)!important;
                }

                &:hover {
                    box-shadow: 0 0 10px 0 rgba(0,0,0,.05);

                    .ff-img-holder::after {
                        background: rgba(0, 0, 0, 0.30);
                    }
                }
            }
        }

        .ff-img-holder {
            width: 100%!important;
            height: 180px!important;
            border-radius: 20px 20px 0 0;
            position: relative;

            img {
                height: 100%;
                object-fit: cover;
            }
        }

        .ff-content {
            @include size(270px!important, 200px!important);
            background-color: $color-white;
            color: $color-dark;
            border-radius: 0 0 20px 20px;
            padding: 15px 15px 60px;
            margin: 0;
            font-size: toRem(14);
            font-weight: 400;
            line-height: toRem(20);
            position: relative;
            z-index: 2;
            transition: all $duration ease-in-out!important;
        }

        .ff-item-cont {
            height: 0!important;
        }

        .ff-item-bar {
            border: 0;
            width: 268px;
            padding: 10px 15px 0;
            position: absolute;
            top: 335px;
            left: 1px;
            z-index: 2;
            background-color: $color-white;
            border-top: 1px solid $color-light;
            color: $color-dark!important;
            border-radius: 0 0 20px 20px;
            transition: all $duration ease-in-out!important;

            a {
                color: $color-dark!important;
            }
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .front-page::before {
        height: 645px;
    }

    .presentation {
        padding-top: 100px;
        margin-bottom: 80px;
    
        &__title {
            font-size: toRem(22);
        }
    
        &__intro {
            width: 720px
        }

        &__button {
            width: 300px;
            font-size: toRem(18);
            line-height: toRem(20);
        }
    }

    .job {
        &__card {
            width: 460px;
        }
    
        &__title {
            font-size: toRem(22);
        }
    }

    .acag {
        margin-top: 100px;
    
        &:before {
            height: 340px;
        }
    
        &__item {
            @include size(300px, 280px!important);
        }
    
        &__image--notimg {
            @include size(100%, 280px);
        }
    
        &__date {
            font-size: toRem(25);
        }
    
        &__title--small {
            font-size: toRem(20);
        }
    
        &__bottom {
            gap: 20px;
        }
    
        &__buttons {
            gap: 20px;
        }
    
        &__button {
            width: 270px!important;
        }
    }

    .social {
        margin: 100px 0 0;
    
        &:before {
            height: 90px;
        }
    
        &__newsletter {
            @include size(220px, 355px);
            padding: 30px 20px;

            p {
                margin: 20px 0 25px;
            }
        }

        &__networks {
            margin: 0 0 25px;
        }
    
        #ff-stream-1 {
            width: 700px;
            min-height: 335px!important;
            max-height: 335px!important;
    
            .ff-stream-wrapper {
                height: 100%!important;
    
                .ff-item {
                    @include size(220px!important, 355px!important);
    
                    &:nth-child(2) {
                        transform: translateX(240px)!important;
                    }
    
                    &:nth-child(3) {
                        transform: translateX(480px)!important;
                    }
                }
            }
    
            .ff-content {
                width: 220px!important;
                font-size: toRem(12);
                height: 170px !important;
                padding: 10px 15px 35px;
            }
    
            .ff-item-bar {
                width: 218px;
                top: 320px;
                height: 35px;
                padding: 5px 15px 0;
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .front-page::before {
        height: 725px;
    }

    .presentation {
        margin-bottom: 75px;

        &__container {
            padding: 30px;
        }
    
        &__title {
            gap: 15px;
            font-size: toRem(20);
            line-height: toRem(25);
    
            span {
                @include size(15px);
            }
        }
    
        &__intro {
            width: calc(100% - 60px);
        }

        &__fleche {
            transform: translateY(10px);
        }

        &__buttons {
            flex-wrap: wrap;
            justify-content: center;
            gap: 15px 20px;
        }
    }

    .job {
        &__card {
            @include size(calc(50% - 10px), 240px);
    
            &:after {
                @include position(absolute, $bottom: 37.5px, $left: 20px);
                @include size(15px);
            }

            &:hover, &:focus {
                .job {
                    &__image::before {
                        @include size(0);
                    }
    
                    &__fleche {
                        left: -20px;
                        opacity: 0;
                        pointer-events: none;
                    }
    
                    &__content {
                        transform: translateY(0);
                    }
    
                    &__title {
                        color: $color-white;
                        text-shadow: 0 0 15px rgba(0,0,0,.5);
                    }
                }
            }
        }
    
        &__content {
            transform: translateY(0);
            bottom: 20px;
            left: 50px;
        }
    
        &__title {
            font-size: toRem(20);
            line-height: toRem(25);
        }

        &__intro {
            display: none;
        }
    }

    .acag {
        margin-top: 80px;
    
        &__title {
            font-size: toRem(30);
            line-height: toRem(35); 
            margin: 0 0 35px;
    
            span {
                @include size(15px);
            }
        }
    
        &__item {
            width: calc(50% - 10px);
    
            &:before {
                background: rgba(0, 0, 0, 0.5);
            }
    
            &:hover, &:focus {
                &:before {
                    background: rgba(0, 0, 0, 0.5);
                }
    
                .acag {
                    &__fleche {
                        @include position(absolute, $top: -3px, $right: -3px);
                    }

                    &__intro {
                        margin: 5px 0 0;
                    }
                }
            }
        }
    
        &__content {
            width: calc(100% - 70px);
        }
    
        &__intro {
            font-size: toRem(14);
            line-height: toRem(20);
            max-height: 100px;
            opacity: 1;
            margin: 5px 0 0;
        }
    
        &__bottom {
            flex-wrap: wrap;
        }
    }

    .social {
        margin: 80px 0 0;
        // margin: 85px 0 0;

        &:before {
            height: 195px;
        }

        &__title {
            font-size: toRem(30);
            line-height: toRem(35); 

            span {
                @include size(15px);
            }
        }

        &__content {
            flex-direction: column;
            gap: 20px;
        }

        &__newsletter {
            @include size(calc(50% - 10px), 315px);
            padding: 30px;

            p {
                margin: 20px 0;
            }
        }

        &__networks {
            margin: 0 0 20px;
        }

        &__button {
            width: 180px;
        }
    
        #ff-stream-1 {
            width: 100%;
            min-height: 315px!important;
            max-height: 315px!important;
    
            .ff-stream-wrapper {
                height: 100%!important;

                .ff-item {
                    @include size(calc(50% - 10px)!important, 315px!important);

                    &:nth-child(1) {
                        transform: translate(calc(100% + 20px), -335px) !important;
                    }
    
                    &:nth-child(2) {
                        transform: translateX(0) !important;
                    }
    
                    &:nth-child(3) {
                        transform: translateX(calc(100% + 20px)) !important;
                    }
                }
            }
    
            .ff-content {
                width: 100% !important;
                height: 125px !important;
            }
    
            .ff-item-bar {
                width: calc(100% - 2px);
                top: 280px;
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .front-page::before {
        height: 975px;
    }

    .presentation {
        margin-bottom: 60px;

        &__container {
            flex-direction: column;
            padding: 25px 25px 35px;
        }

        &__title span {
            min-width: 15px;
        }

        &__intro {
            width: 100%;
            font-size: toRem(16);
            line-height: toRem(25);
            margin-top: 5px;
        }

        &__buttons {
            margin: 15px 0 0;
        }
    }

    .job {
        &__cards {
            flex-direction: column;
            gap: 20px;
        }
    
        &__card {
            width: 100%;
        }

        &__title {
            font-size: toRem(18);
            line-height: toRem(22);
        }
    }

    .acag {
        padding-bottom: 60px;

        &:before {
            height: 300px;
        }

        &__title {
            font-size: toRem(25);
            line-height: toRem(30);
            gap: 10px;
            margin: 0 0 30px;
        }

        &__bottom {
            justify-content: center;
            margin: 20px 0 0;
            gap: 30px;
        }

        &__buttons {
            justify-content: center;
            flex-wrap: wrap;
            gap: 15px;
        }
    }

    .social {
        &__title {
            font-size: toRem(25);
            line-height: toRem(30);
            gap: 10px;
        }

        &__newsletter {
            @include size(100%, fit-content);
            padding: 25px;
            flex-direction: row;
            flex-wrap: wrap;
        }

        &__networks {
            margin: 0 20px 20px 0;
        }
    
        #ff-stream-1 {
            min-height: 985px!important;
            max-height: 985px!important;
    
            .ff-stream-wrapper {
                .ff-item {
                    width: 100%!important;

                    &:nth-child(1) {
                        transform: translate(0, 0) !important;
                    }
    
                    &:nth-child(2) {
                        transform: translateY(335px) !important;
                    }
    
                    &:nth-child(3) {
                        transform: translateY(670px) !important;
                    }
                }
            }
        }
    }
}
