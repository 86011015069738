.document-details {
    @include flex($direction: column, $alignItems: flex-start, $justifyContent: center);
    gap: 25px;
    width: 100%;

    &__title {
        margin: 0;
    }

    &__infos {
        @include flex($direction: column, $wrap: wrap, $alignItems: flex-start, $justifyContent: center);
        row-gap: 15px;
        width: 100%;
    }

    &__category {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: $taxo-padding;
        background-color: $color-light;
        border: $taxo-border-width $taxo-border-style $taxo-border-color;
        border-radius: 50px;
        font-family: $taxo-font-family;
        font-size: $taxo-font-size;
        font-weight: $taxo-font-weight;
        line-height: $taxo-line-height;
        text-transform: $taxo-text-transform;
        color: $taxo-color;
    }

    &__list {
        @include flex($direction: row, $wrap: wrap, $alignItems: center);
        row-gap: 10px;
    }

    &__item {
        position: relative;
        display: flex;
        align-items: center;
        color: $color--card;
        
        &:after {
            content: '';
            display: inline-block;
            vertical-align: bottom;
            margin: auto 12px;
            width: 1px;
            height: 15px;
            background-color: $color-light;
        }

        &--extension {
            text-transform: uppercase;
        }
    }

    &__actions {
        @include flex($direction: row, $wrap: wrap, $alignItems: center);
        gap: 20px;
    }

    // Card documents avec image
    &--image {
        @include flex($wrap: nowrap, $direction: row, $alignItems: center, $justifyContent: unset);
        gap: 25px 30px;
    }

    // Card documents avec image -- link-document--feuilleter
    &__link {
        flex: none;
        // A enlever si le fond des cards n'est pas blanc
        border: 1px $btn-border-style $color-light;
    }

    // Card documents avec image -- figure
    &__image {
        height: 220px;
        max-width: 315px;
        margin: 0;
        line-height: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    // Card documents avec image -- bloc details + actions btns
    &__content {
        @include flex($direction: column, $alignItems: flex-start, $justifyContent: center);
        gap: 25px;
        width: 100%;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================


// 960
@media screen and (max-width: $medium) {

    .document-details {
        &--image {
            flex-wrap: wrap;
        }

        // Card documents avec image -- link-document--feuilleter
        &__link {
            max-width: 100%;
        }

    }

}


// 640
@media screen and (max-width: $small) {

    .document-details__image {
        max-width: 100%;
        height: 182px;
    }

}
