.scrollTopBtn {
    @include fixed($right: 20px, $bottom: 20px);
    z-index: 9;
    @include size(50px);
    padding: 0;
    margin: 0;
    background-color: $color-fifth;
    border: 2px solid $color-fifth;
    border-radius: 15px;
    opacity: 0;
    transform: translateY(100px);
    transition: {
        property: opacity, transform, background-color, border-color;
        duration: .5s, .5s, $duration, $duration;
        timing-function: $timing;
    }

    &--show,
    &--show:focus  {
        opacity: 1;
        transform: translateY(0);
    }

    svg {
        fill: $color-dark;
        @include size(100%);
        transition: fill $duration $timing;
    }

    &:hover,
    &:focus {
        background-color: $color-white;
        border-color: $color-fifth;
    }
}