//===============================================
// Actions buttons
//===============================================
.documents-details__button {
    @include flex($alignItems: center);
    column-gap: 10px;
    margin: 0;
    cursor: pointer;
    font-family: $font-family;
    font-size: toRem(14);
    font-weight: 500;
    line-height: toRem(20);
    text-transform: uppercase;
    color: $color--card;
    position: relative;

    &:before {
        @include position(absolute, $right: 0, $bottom: 5px);
        @include size(calc(100% - 40px), 2px);
        content: "";
        background-color: $color-gray;
        z-index: 0;
        opacity: 0;
        transition: all $duration ease-in-out;
    }

    svg {
        @include size($btn-action-size);
        background-color: $btn-action-bg;
        border: $btn-action-width $btn-action-style $btn-action-border-color;
        border-radius: $btn-action-border-radius;
        fill: $btn-action-color;
        transition: {
            property: background-color, border-color, fill;
            duration: $duration;
            timing-function: $timing;
        }
    }

    &:hover, &:focus {
        color: $color-dark;

        &:before {
            opacity: 1;
        }
    }
}