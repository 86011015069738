//======================================================================================================
// Listing
//======================================================================================================
.listing {
    &__line {
        display: flex;
        flex-wrap: wrap;
        padding: 15px 10px;
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);

        > svg {
            width: 60px;
            height: 60px;
            margin: 0 10px 0 0;
            fill: $color-svg--events;  
        }

        div,
        p {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 5px;
        }

        div {
            margin-bottom: 5px;
        }

        p {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &--accessibility {
            align-items: center;
            margin: 0 20px 0 0;
            padding: 0;
            box-shadow: none;

            &:first-of-type {
                width: 100%;
                margin: 0 0 20px;
            } 
        }

        &--files {
            div,
            p {
                display: block; 
            }
        }
    }

    &__title {
        font-size: $font-size--text-small;
        font-weight: $font-weight-bold;
        color: $color--card;
        text-transform: uppercase;
        margin-bottom: 5px;

        &--accessibility {
            color: $color--card;
        }

        &--files {
            font-family: $font-family;
            font-size: toRem(18);
            font-weight: 500;
            line-height: toRem(20);
            color: $color--card;
            text-transform: initial;
            margin: 20px 0;
        }
    }
    
    &--accessibility {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 25px 10px;
    }

    &-contact {
        li {
            display: flex;
            flex-direction: column;

            &:not(:first-child) {
                margin-top: 15px;
            }
        }

        &__name {
            font-weight: $font-weight-bold;
        }

        &__mail {
            display: flex;
            text-decoration: underline;
            word-break: break-all;
            
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
// @media screen and (max-width: $large) {

// }


// 960
// @media screen and (max-width: $medium) {

// }


// 640
// @media screen and (max-width: $small) {


// }
