.footer {
    background-color: $color-light;
    padding-top: 100px;

    &__top {
        display: flex;
    }

    &__infos {
        width: 165px;
        margin-right: 200px;
    }

    &__horaires {
        width: 400px;
    }

    &__title {
        font-size: toRem(18);
        font-weight: 500;
        line-height: toRem(20);
    }

    &__text {
        font-size: toRem(16);
        font-weight: 400;
        line-height: toRem(25);
        margin: 10px 0 0;
    }

    &__buttons {
        @include flex($alignItems: center);
        gap: 140px;
        margin: 40px 0 0;
    }

    &__button {
        background-color: rgba($color-dark, 0.2);
        border: 2px solid $color-fifth;
        margin: 0;
        padding: 0;
        height: 50px;

        svg {
            @include size(15px);
        }

        &--contact, &--partners {
            width: 225px;
        }

        &--tel {
            width: 250px;
        }

        &:hover, &:focus {
            background-color: transparent;
            border-color: $color-fifth;
        }
    }

    &__menu {
        &__list {
            @include flex($wrap: wrap, $alignItems: center, $justifyContent: flex-start);
            width: 100%;
            padding: 40px 0 80px;
            gap: 109px;
        }

        &__item {
            position: relative;
            padding: 0;
            font-size: toRem(14);
            font-weight: 400;
            line-height: toRem(20);

            &::before {
                content: '';
                @include absolute($top: 50%, $right: -60px);
                @include size(8px);
                border: 1px solid $color-dark;
                box-sizing: content-box;
                border-radius: 50%;
                box-sizing: border-box;
                transform: translateY(-50%);
            }
            
            &:after {
                @include position(absolute, $left: 0, $bottom: -2px);
                @include size(100%, 2px);
                content: "";
                background-color: $color-gray;
                z-index: 0;
                opacity: 0;
                transition: all $duration ease-in-out;
            }

            &:last-child::before {
                display: none;
            }

            &:hover, &:focus {
                &:after {
                    opacity: 1;
                }
            }
        }

        &__link {
            color: $color-text;

            &:hover, &:focus {
                color: $color-text;
            }
        } 
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .footer {
        &__infos {
            margin-right: 160px;
        }

        &__buttons {
            gap: 100px;
        }

        &__menu {
            &__list {
                gap: 60px;
            }

            &__item::before {
                right: -35px;
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .footer {
        padding-top: 80px;

        &__content {
            @include flex($alignItems: flex-end, $justifyContent: space-between);
        }

        &__top {
            display: flex;
            flex-direction: column;
            gap: 30px;
            width: 50%;
        }
        
        &__infos {
            margin-right: 0;
        }

        &__horaires {
            width: unset;
            margin-bottom: -5px;
        }

        &__buttons {
            flex-direction: column;
            align-items: flex-end;
            gap: 25px;
            margin: 0 20px 0 0;
            width: 50%;
        }

        &__button {
            width: 230px!important;
        }

        &__menu {
            &__list {
                width: calc(100% - 80px);
                gap: 15px 60px;
                padding: 56px 0 140px;
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .footer {
        &__content {
            flex-direction: column;
            align-items: flex-start;
            gap: 35px;
        }

        &__top {
            width: 100%;
        }

        &__buttons {
            align-items: flex-start;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 15px;
            width: 100%;
        }

        .footer__menu__list {
            padding: 40px 0 120px;
        }
    }
}
