.bloc-table {
    width: auto;
    margin: 20px 0;
    border: $table-border-width solid $table-border-color;

    tr {
        background-color: transparent;
        color: $table-color;

        &.head-color {
            &--alternate {

                &:nth-child(odd) {
                    background-color: $table-odd-bg;
                    color: $table-odd-color;

                    a {
                        color: $table-odd-color;
            
                        &:hover, &:focus {
                            color: $table-odd-color;
                        }
                    }
                }

                &:nth-child(even) {
                    background-color: $table-even-bg;
                    color: $table-even-color;

                    a {
                        color: $table-even-color;
            
                        &:hover, &:focus {
                            color: $table-even-color;
                        }
                    }
                }
            }

            &--both:first-child {
                background-color: $color-gray;
                color: $table-head-color;

                a {
                    color: $table-head-color;
        
                    &:hover, &:focus {
                        color: $table-head-color;
                    }
                }

                td {
                    border-left-color: $table-head-border-color;
                    border-right-color: $table-head-border-color;

                    &:first-child {
                        border-left-color: $color-gray;
                    }

                    &:last-child {
                        border-right-color: $table-border-color;
                    }
                }
            }

            &:first-child {
                background-color: $table-head-bg;
                color: $table-head-color;

                td {
                    border-left-color: $table-head-border-color;
                    border-right-color: $table-head-border-color;

                    &:first-child {
                        border-left-color: $table-border-color;
                    }

                    &:last-child {
                        border-right-color: $table-border-color;
                    }
                }

                a {
                    color: $table-head-color;
        
                    &:hover, &:focus {
                        color: $table-head-color;
                    }
                }
            }
        }

        &:first-child {
            .head-color, .head-color--both {
                &:first-child {
                    border-top-color: $color-gray;
                }
            }
        }

        &:last-child {
            .head-color, .head-color--both {
                &:first-child {
                    border-bottom-color: $table-border-color;
                }
            }
        }
    }
    
    td {
        padding: 10px;
        border: $table-border-width solid $color-gray;

        a {
            color: $table-color;
            text-decoration: underline;

            &:hover, &:focus {
                color: $table-color;
                text-decoration: none;
            }
        }

        &.head-color {
            &--alternate {

                &:nth-child(odd) {
                    background-color: $table-odd-bg;
                    color: $table-odd-color;

                    a {
                        color: $table-odd-color;
            
                        &:hover, &:focus {
                            color: $table-odd-color;
                        }
                    }
                }

                &:nth-child(even) {
                    background-color: transparent;
                    color: $table-even-color;

                    a {
                        color: $table-even-color;
            
                        &:hover, &:focus {
                            color: $table-even-color;
                        }
                    }
                }
            }

            &--both:first-child {
                background-color: $color-gray;
                border-top-color: $table-head-border-color;
                border-bottom-color: $table-head-border-color;
                color: $table-head-color;

                a {
                    color: $table-head-color;
        
                    &:hover, &:focus {
                        color: $table-head-color;
                    }
                }
            }

            &:first-child {
                background-color: $color-gray;
                border-top-color: $table-head-border-color;
                border-bottom-color: $table-head-border-color;
                color: $table-head-color;

                a {
                    color: $table-head-color;
        
                    &:hover, &:focus {
                        color: $table-head-color;
                    }
                }
            }
        }
    }

    p {
        font-size: $font-size--text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    
    .table--responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
    }

}
