@keyframes headerFixed {
    from {
        top: -200px;
    }
    to {
        top: 0;
    }
}

.header {
    position: relative;
    z-index: 10;
    height: 130px;

    &__container {
        background-color: $color-white;
        height: 100%;
    }

    &__content {
        height: 100%;

        > .container {
            @include flex($justifyContent: space-between);
            height: 100%;
        }
    }

    &__infos {
        @include flex($alignItems: center);
        gap: 15px;
    }

    &__link {
        @include position(absolute, $top: 0, $left: 50%);
        transform: translatex(-50%);
        @include flex($alignItems: center, $justifyContent: center);
        @include size(330px, 190px);
        background-color: $color-white;
        border-radius: 0px 0px 20px 20px;
        box-shadow: $shadow;

        .logo {
            margin: -5px 0 0 0;
        }

        .logo-fixe {
            display: none;
        }
    }

    &__title {
        font-size: 0;
        line-height: 0;
        @include size(0);
        display: flex;
    }

    &__rdv {
        @include flex($alignItems: center, $justifyContent: center, $direction: column);
        @include size(155px, 100px);
        gap: 5px;
        background-color: $color-gray;
        border-top: unset;
        color: $color-white;
        border-radius: 0px 0px 15px 15px;
        transition: all $duration ease-in-out;

        svg {
            @include size(25px);
            fill: $color-white;
            transition: all $duration ease-in-out;
        }
        
        p {
            font-family: $font-family--heading;
            font-size: toRem(18);
            font-weight: 400;
            line-height: toRem(22);
            text-transform: uppercase;
            text-align: center;
            padding: 0 30px;
        }

        &:hover, &:focus {
            background-color: $color-second;
            color: $color-white;
        }
    }

    &--fixed {
        .header {
            &__container {
                @include position(fixed, $top: 0, $left: 0, $bottom: 0, $right: 0);
                height: 80px;
                z-index: 2;
                box-shadow: 1px 0px 20px 0px rgba(0, 0, 0, 0.10);
                border-radius: 0px 0px 20px 20px;
                animation: 1s headerFixed;
            }

            &__link {
                @include size(fit-content);
                box-shadow: none;
                .logo {
                    display: none;
                }
        
                .logo-fixe {
                    display: flex;
                }
            }
        }
    }
}

//======================================================================================================
// Tools
//======================================================================================================
.tools {
    @include flex($alignItems: center);
    gap: 15px;

    &__item {
        @include size(50px);
        border: 2px solid;
        border-radius: 10px;
        background-color: transparent;
        margin: 0;
        padding: 0;

        svg {
            fill: $color-dark;
            @include size(100%);
        }

        &--accessibility {
            border-color: $color-fourth;

            &:hover, &:focus {
                background-color: $color-fourth;
            }
        }

        &--search {
            border-color: $color-gray;

            &:hover, &:focus {
                background-color: $color-gray;
                border-color: $color-gray;
                
                svg {
                    fill: $color-white;
                }
            }

            &--disabled {
                opacity: 0.3;
                pointer-events: none;
            }
        }
    }

    #accessconfig {
        max-height: 50px;
    }

}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .header__link {
        @include size(280px, 170px);

        .logo {
            @include size(210px, 95px);
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .header {
        &__content {
            @include position(fixed, $left: 0, $bottom: 0);
            @include size(100vw, 80px);
            background-color: $color-white;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.07);
            border-radius: 15px 15px 0px 0px;
            z-index: 2;
        }

        &__infos {
            gap: 10px;
        }

        &__rdv {
            border-radius: 15px 15px 0px 0px;
            margin-top: -20px;
            border-top: 2px solid $color-gray;

            &:hover, &:focus {
                background-color: $color-gray;
                color: $color-white;
    
                svg {
                    fill: $color-white;
                }
            }
        }
    }

    .tools {
        &__item {
            @include size(40px);

            svg {
                @include size(40px);
                min-width: 40px;
            }
        }
    
        #accessconfig {
            max-height: 40px;
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .header {
        &__content {
            height: 70px;
        }

        &__rdv {
            @include size(145px, 90px);
            
            p {
                font-size: toRem(16);
                line-height: toRem(20);
            }
        }
    }
}
