//======================================================================================================
// Variables
//======================================================================================================

// Global
$vi-height: 120px;
$vi-padding--card: 10px;
$vi-border-radius: $border-radius;
$vi-duration: .3s;

// Colors
$vi-bg : $color-white;
$vi-bg--open: $color-bg--neutral;
$vi-color: $color-text;

// Icon
$vi-icon-bg: $vi-bg--open;
$vi-icon-bg--hover: $vi-bg--open;
$vi-icon-border: 0;
$vi-icon-border-color--hover: unset;
$vi-icon-border-radius: $border-radius--round;
$vi-icon-color: $color-text;
$vi-icon-color--hover: $color-text;
// Only for tabs
$vi-icon-bg--active: $color-white;
$vi-icon-border-color--active: unset;
$vi-icon-color--active: unset;

// If image
$vi-height--image: 120px;
$vi-padding--card--image: 10px; // no padding : 0
$vi-border-radius--image: $vi-border-radius; // if no padding on card : $vi-border-radius 0 0 $vi-border-radius
$vi-border-radius--image--tabactive: $vi-border-radius; // if no padding on card :  $vi-border-radius 0 0 0

// comment if not shadow
$vi-shadow: $shadow;
$vi-shadow--hover: $shadow--hover;

// uncomment if border
// $vi-border: 1px solid $color-main;
// $vi-border-color--hover: $color-second;

// CSS variables for js
.js-vignettesPanel {
    // variable padding panel
    --panelPadding : 30px;

    // variable margin-top panel
    --panelMargin : 10px;

    &__content {
        // variable transform translate Y panel
        --panelTranslate : 100px;
    }
}


//======================================================================================================
// Global
//======================================================================================================

.bloc-vignettes {
    margin-top: 30px;
}

// Vignettes base
.vignettes {
    &__list {
        @include flex($direction: row, $wrap: wrap);
        gap: 15px;

        // For vignettes with paragraph // panel
        &--tab {
            position: relative;
        }
    }

    &__item {
        width: 100%;
        max-width: calc((100% - (15px * 3)) / 4);

        &--alone, &--two {
            max-width: calc((100% - 15px) / 2);
        }

        &--three {
            max-width: calc((100% - (15px * 2)) / 3);
        }

        &:hover, &:focus-within {
            .vignettes__icon {
                background-color: $vi-icon-bg--hover;
                border-color: $vi-icon-border-color--hover;

                svg {
                    fill: $vi-icon-color--hover;
                }
            }
        }
    }

    &__card {
        cursor: pointer;
        position: relative;
        @include flex($direction: row, $wrap: nowrap, $alignItems: center);
        column-gap: 15px;
        height: $vi-height;
        padding: $vi-padding--card 20px;
        background-color: $vi-bg;
        border-radius: $vi-border-radius;
        transition: {
            property: background-color, box-shadow, border-color;
            duration: $vi-duration;
            timing-function: $timing;
        }

        // if box shadow
        box-shadow: $vi-shadow;
        
        // if border
        // border: $vi-border;

        &:hover, &:focus-within {
            // if box shadow
            box-shadow: $vi-shadow--hover;

            // if border
            // border-color: $vi-border-color--hover;
        }

        &--image {
            height: $vi-height--image;
            padding: $vi-padding--card--image 20px $vi-padding--card--image $vi-padding--card--image;
        }
    }

    &__image {
        @include size(auto, 100%);
        max-width: 110px;
        object-fit: cover;
        object-position: top;
        flex: none;
        border-radius: $vi-border-radius--image;
    }

    &__title {
        padding-right: 10px;
        font-size: $font-size--text;
        font-weight: $font-weight-bold;
        color: $vi-color;
        line-height: $line-height--heading;
    }

    &__icon {
        @include absolute($bottom: 10px, $right: 10px);
        @include size(20px);
        background-color: $vi-icon-bg;
        border: $vi-icon-border;
        border-radius: $vi-icon-border-radius;
        transition: background-color $vi-duration $timing;
        transition: {
            property: background-color, border-color;
            duration: $vi-duration;
            timing-function: $timing;
        }

        svg {
            @include size(20px);
            fill: $vi-icon-color;
            transition: fill $vi-duration $timing;
        }
    }

}

// Vignettes with paragraph // panel
.vignettes {

    &__item {
        &--tab {
            margin-bottom: 0;
            transition: margin $vi-duration $timing;

            // For item first line
            &:first-child, &:nth-child(4n + 1) {
                .vignettes__panel {
                    border-top-left-radius: 0;
                }
            }

            // For item last line
            &:nth-child(4n) {
                .vignettes__panel {
                    border-top-right-radius: 0;
                }
            }

            // Only for Three or Two items
            &.vignettes__item--three, &.vignettes__item--two {
                &:last-child {
                    .vignettes__panel {
                        border-top-right-radius: 0;
                    }
                }
            }

            // Only for Alone item
            &.vignettes__item--alone {
                .vignettes__panel {
                    --panelMargin : 0;
                }
            }
        }
        
    }
    
    // vignettes__card
    &__tab {
        border-radius: $vi-border-radius;
    }

    &__icon--button {
        transition: {
            property: background-color, border-color, transform;
            duration: $vi-duration;
            timing-function: $timing;
        }
    }
    
    &__panel {        
        @include absolute($left: 0);
        @include size(100%, 0);
        padding: 0 var(--panelPadding);
        overflow: hidden;
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
        background-color: $color-bg--neutral;
        border-radius: $vi-border-radius;
        transform: translateY(0);
        transform-origin: top;
        transition: {
            property: height, padding, transform, opacity, pointer-events, visibility;
            duration: $vi-duration;
            timing-function: $timing;
        }
        
        // if border
        // border: $vi-border;

        &__content {
            opacity: 0;
            margin: 0;
            padding: 0;
            transform: translateY(var(--panelTranslate));
            // transition-delay: 0ms; // Disappear before open vignette panel
            transition: {
                property: opacity, transform;
                duration: $vi-duration;
                timing-function: $timing;
            }

            :is(h1, h2, h3, h4, h5, h6, p):first-child {
                margin-top: 0;
            }
        }

    }
}

// Open vignettes
.js-vignettesActive {

    .vignettes {

        // vignettes__card
        &__tab {
            position: relative;
            box-shadow: none;
            background-color: $vi-bg--open;
            border-radius: $vi-border-radius $vi-border-radius 0 0;

            // if border
            // border-bottom: 0;
            // border-color: $vi-border-color--hover;

            &::after {
                content: '';
                @include absolute($bottom: -11px, $left: 0, $right: 0);
                z-index: 1;
                height: 11px;
                background-color: $vi-bg--open;

                // if border on panel
                // @include absolute($bottom: -11px, $left: -1px, $right: -1px);
                // border: $vi-border;
                // border-color: $vi-border-color--hover;
                // border-top: 0;
                // border-bottom: 0;
            }
        }
        
        &__image {
            border-radius: $vi-border-radius--image--tabactive;
        }
        
        &__icon {
            transform: rotate(-180deg);
            background-color: $vi-icon-bg--active;
            border-color: $vi-icon-border-color--active;
            
            svg {
                fill: $vi-icon-color--active;
            }
        }

        &__panel {
            opacity: 1;
            pointer-events: initial;
            visibility: initial;
            padding: var(--panelPadding);
            transform: translateY(var(--panelMargin));

            // if border
            // border-color: $vi-border-color--hover;

            &__content {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================


// 1200
@media screen and (max-width: $large) {

    // CSS variables for js
    .js-vignettesPanel {
        // variable padding panel
        --panelPadding : 20px;
    }

    // Vignettes base
    .vignettes {
        &__item {
            max-width: calc((100% - (15px * 2)) / 3);

            &--alone, &--two {
                max-width: calc((100% - 15px) / 2);
            }
    
            &--three {
                max-width: calc((100% - (15px * 2)) / 3);
            }
        }
    }

    // Vignettes with paragraph // panel
    .vignettes {
        &__item {
            &--tab {

                &:nth-child(4n + 1), &:nth-child(4n) {
                    .vignettes__panel {
                        border-radius: $vi-border-radius;
                    }
                }

                // For item first line
                &:first-child, &:nth-child(3n + 1) {
                    .vignettes__panel {
                        border-top-left-radius: 0;
                    }
                }

                // For item last line
                &:nth-child(3n) {
                    .vignettes__panel {
                        border-top-right-radius: 0;
                    }
                }
            }
            
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    // Vignettes base
    .vignettes {
        &__item {
            max-width: calc((100% - 15px) / 2);

            &--two, &--three {
                max-width: calc((100% - 15px) / 2);
            }

            &--alone {
                max-width: 100%;
            }
        }
    }

    // Vignettes with paragraph // panel
    .vignettes {
        &__item {
            &--tab {
                margin-bottom: unset;
                transition: none;

                @keyframes AnimMarginclose {
                    from {
                        margin-bottom: var(--panelTotalMargin);
                    }
                    
                    to {
                        margin-bottom: 0;
                    }
                }
            
                // animation only for tablet / smartphone
                animation-duration: $vi-duration;
                animation-name: AnimMarginclose;
                animation-iteration-count: 1;
                animation-direction: initial;
                animation-fill-mode: forwards;

                &:nth-child(3n + 1), &:nth-child(3n), &.vignettes__item--three:last-child {
                    .vignettes__panel {
                        border-radius: $vi-border-radius;
                    }
                }

                // For item first line
                &:first-child, &:nth-child(2n + 1), &.vignettes__item--three:last-child {
                    .vignettes__panel {
                        border-top-left-radius: 0;
                    }
                }

                // For item last line
                &:nth-child(2n) {
                    .vignettes__panel {
                        border-top-right-radius: 0;
                    }
                }
            }
            
        }

        &__panel {
            transform: none;
            transition-property: padding, opacity, pointer-events, visibility;
            height: var(--panelTotalHeight);

            @keyframes AnimHeightclose {
                from {
                    height: var(--panelTotalHeight);
                }
                
                to {
                    height: 0;
                }
            }
        
            // animation only for tablet / smartphone
            animation-duration: $vi-duration;
            animation-name: AnimHeightclose;
            animation-iteration-count: 1;
            animation-direction: initial;
            animation-fill-mode: forwards;
            // animation-delay: 100ms;
        }
    }

    // Open vignettes
    .js-vignettesActive {

        @keyframes AnimMarginopen {
            from {
                margin-bottom: 0;
            }
            
            to {
                margin-bottom: var(--panelTotalMargin);
            }
        }

        // animation only for tablet / smartphone
        animation-duration: $vi-duration;
        animation-name: AnimMarginopen;
        animation-iteration-count: 1;
        animation-direction: initial;
        animation-fill-mode: forwards;

        .vignettes__panel {
            @keyframes AnimHeightopen {
                from {
                    height: 0;
                }
                
                to {
                    height: var(--panelTotalHeight);
                }
            }
        
            // animation only for tablet / smartphone
            animation-duration: $vi-duration;
            animation-name: AnimHeightopen;
            animation-iteration-count: 1;
            animation-direction: initial;
            animation-fill-mode: forwards;
            animation-delay: 0ms;
        
        }
    }
}

// 640
@media screen and (max-width: $small) {

    // CSS variables for js
    .js-vignettesPanel {
        // variable margin-top panel
        --panelMargin : 0;

        &__content {
            // variable transform translate Y panel
            --panelTranslate : 0;
            opacity: 1;
        }
    }

    .vignettes {

        &__list {
            gap: 10px;
        }

        &__item {
            max-width: 100%;
        }

        &__card {
            height: 80px;
        }

        &__image {
            max-width: 60px;
        }

        &__panel {
            transform: scale(1);
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
            
            // if border
            // border-top: 0;
        }
    }

    .js-vignettesActive .vignettes__tab::after {
        display: none;
    }
}
