
//=================================================================================================
// Filter maps
//=================================================================================================
#filters-maps {

    #mapKeywordsFilter {
        background-color: $color-white;
    }
    
    &.load {
        .filter {
            &__input-container {
                &.maps-term {
                    pointer-events: none;

                    label,
                    input {
                        pointer-events: none;
                    }
                }
            }

            &__term-container {
                li {
                    pointer-events: none;

                    label,
                    input {
                        pointer-events: none;
                    }
                }
            }
        }
    }

    .filter {
        margin-top: 0;
        &__select-wrapper {

            &.is-open {
                box-shadow: none;

                .filter__input-container.maps-term {
                    label {
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }

                    .filter__input-svg {
                        transform: rotate(180deg);
                        fill: $f-maps-input-icon--selected;
                    }
                }
            }

            &.selected {
                .filter-container {
                    background: $color-gray;
                    border-color: $f-input-border-color--selected;
                }

                .filter__input-container.maps-term {
                    .filter__input-svg {
                        fill: $f-maps-input-icon--checked;
    
                        &:hover, &:focus {
                            fill: $f-maps-input-icon--checked;
                        }
                    }
                }

                .filter__term-container {
                    label {
                        background-color: $color-white;
                        color: $color-dark;
                    }
                }
            }
        }

        &__input-container {
            &.maps-term {
                display: flex;
                align-items: center;
                flex-direction: row-reverse;
                justify-content: space-between;
                margin-bottom: 10px;
                border-radius: $border-radius;

                label {
                    @include flex($alignItems: center);
                    cursor: pointer;
                    pointer-events: auto;
                    width: 100%;
                    min-height: 50px;
                    margin: 0;
                    padding: 4px 0 4px 5px;
                    background-color: $color-white;
                    border: $f-maps-input-border-width $border-style $f-maps-input-border-color;
                    border-radius: $f-maps-input-border-radius;
                    color: $f-maps-input-color;
                    font-size: $f-maps-input-font-size;
                    font-weight: $font-weight;
                    transition: {
                        property: background-color, border-color, color;
                        duration: $duration;
                        timing-function: $timing;
                    }

                    &:hover, &:focus {
                        background-color: $color-gray;
                        border-color: $color-gray;
                        color: $f-maps-input-color--checked;

                        svg {
                            fill: $f-maps-input-color--checked;
                        }

                        ~ .filter__input-svg {
                            fill: $f-maps-input-icon--checked !important;
                        }
                    }

                    svg {
                        @include size(40px);
                        margin-right: 5px;
                        fill: $f-maps-input-color;
                        transition: fill $duration $timing;
                    }
                }

                input[type=checkbox] {
                    visibility: hidden;
                    position: absolute;

                    &:checked {
                        ~ label {
                            background: $color-gray;
                            border-color: $f-maps-input-border-color--checked;
                            border-bottom-color: $f-maps-input-icon--checked;
                            color: $f-maps-input-color--checked;
                            
                            svg {
                                fill: $f-maps-input-color--checked;
                            }
                        }
                    }
                }

                .filter__input-svg {
                    &:hover, &:focus {
                        fill: $f-maps-input-icon--selected;
                    }
                }
            }
        }

        .filter-container {
            position: relative;
            top: -10px;
            background: $color-fifth;
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
            box-shadow: none;
        }

        #mapKeywordsFilter {
            padding: 15px 12px;
            margin-bottom: 20px;
        }

        &__input-search {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            opacity: 0;
            position: absolute;
            top: 50%;
            right: 10px;
            width: 26px;
            height: 26px;
            padding: 0;
            margin: 0;
            background: $maps-Keyword-close-bg;
            border: 1px solid $maps-Keyword-close-border-color;
            border-radius: $btn-spe-border-radius;
            transform: translateY(-50%);
            transition: 0s;

            svg {
                position: relative;
                right: auto;
                width: 11px;
                height: 11px;
                fill: $maps-Keyword-close-color;
                transition: 0s;

                &.svg-close {
                    display: none;
                    stroke: $maps-Keyword-close-color;
                    stroke-width: 2px;
                }
            }

            &.reset-filter {
                svg {
                    &.svg-search {
                        display: none;
                    }
                    &.svg-close {
                        display: block;
                    }
                }
            }

            &::before {
                content: 'Aucun résultat';
                display: none;
            }

            &:hover,
            &:focus,
            &:visited,
            &:active {
                background: $maps-Keyword-close-bg--hover;

                &::before {
                    color: $maps-Keyword-close-color--hover;
                }

                svg {
                    fill: $maps-Keyword-close-color--hover;

                    &.svg-close {
                        stroke: $maps-Keyword-close-color--hover;
                    }
                }
            }
        }

        input.filter__input { 
            &.selected {
                // Close button Keywords input search
                & ~ .filter__input-search {
                    opacity: 1;
                }
            }
        }

        .no-result {
            .filter__input-search {
                border: 0;

                &:hover,
                &:focus,
                &:visited,
                &:active {
                    &::before {
                        background: $maps-Keyword-close-bg--hover;
                        color: $maps-Keyword-close-color--hover;
                    }
                }

                &::before {
                    display: flex;
                    position: absolute;
                    right: -2px;
                    flex: 1 0 0;
                    height: 27px;
                    min-width: 130px;
                    padding: 5px 10px;
                    background: $maps-Keyword-close-bg;
                    border: 1px solid $maps-Keyword-close-border-color;
                    border-radius: $btn-spe-border-radius;
                    font-size: $font-size--text-small;
                    font-family: $font-family;
                    font-weight: $font-weight;
                    text-transform: none;
                    line-height: $line-height--heading;
                    transition: 0s;
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .filter__all-select {
        margin-top: 5px;
    }

}