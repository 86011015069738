.breadcrumb {
    ul {
        display: block;
        min-height: 60px;
        padding: 18px 0;
    }

    li {
        position: relative;
        display: inline;
        color: $color-dark;
        font-family: $font-family;
        font-size: toRem(16);
        font-weight: 400;
        line-height: toRem(25);

        svg {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            fill: $color-main;
            vertical-align: text-top;
        }

        a {
            position: relative;

            &:before {
                @include position(absolute, $left: 0, $bottom: -2px);
                @include size(100%, 2px);
                content: "";
                background-color: $color-gray;
                z-index: 0;
                opacity: 0;
                transition: all $duration ease-in-out;
            }
        }

        &:hover, &:focus {
            a {
                color: $color-dark;

                &:before {
                    opacity: 1;
                }
            }
        }
    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .breadcrumb {
        li svg {
            margin: 0;
        } 
    }

}


// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        li svg {
            margin: 0 -1px;
        } 
    }

}
