.card {
    width: 100%;
    margin-bottom: 15px;

    &__container {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-content: stretch;
        min-height: 160px;
        padding: $card--padding;
        background-color: $color-bg--card;
        border-radius: $border-radius;
        box-shadow: $shadow;
        color: $color--card;
        overflow: hidden;
        transition: all .5s $timing;

        &--search {
            min-height: 60px;

            .card__head-content {
                display: flex;
                align-items: flex-start;
                flex-direction: column-reverse;
                gap: 15px;

                .card__title {
                    margin: 0;
                }
            }

            .card__description {
                margin-top: 10px;
            }
        }

        // For hover & focus card && not hover card spe
        &:hover, &:focus {
            box-shadow: none;
            background-color: $color-light;

            .card__category {
                background-color: $color-white;
            }

            &.card__container {
                &--document, &--portraits, &--chronology {
                    box-shadow: $shadow;
                    background-color: $color-white;

                    .card__category {
                        background-color: $color-light;
                    }
                }
    
                &--search {
                    box-shadow: none;
                    background-color: $color-light;

                    .card__category {
                        background-color: $color-white;
                    }
                }
            }
        }
    }

    &__image-wrapper {
        width: 350px;
        height: 200px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
            transition: transform .5s $timing;
        }
    }

    &__link {
        &:hover, &:focus {
            .card__image-wrapper img {
                transform: scale(1.03);
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        position: relative;
        padding: $card-content--padding;
    }

    &__infos {
        display: flex;
        flex-wrap: wrap;
    }

    &__category {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: $taxo-padding;
        background-color: $color-light;
        border: $taxo-border-width $taxo-border-style $taxo-border-color;
        border-radius: 50px;
        font-family: $taxo-font-family;
        font-size: $taxo-font-size;
        font-weight: $taxo-font-weight;
        line-height: $taxo-line-height;
        text-transform: $taxo-text-transform;
        color: $taxo-color;
        transition: all $duration ease-in-out;
    }

    &__actions {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-bg--icons;
        border-radius: 100%;
        transition: background-color $duration $timing;

        svg {
            width: 26px;
            height: 26px;
            fill: $color-second--card;
            transition: fill $duration $timing;
        }

        &:hover, &:focus {
            background-color: $color-main;

            svg {
                fill: $color-white;
            }
        }
    }

    &__title {
        text-transform: none;
        font-family: $font-family;
        font-size: $font-size--4;
        color: $color--card;
        border: none;
        margin: 15px 0 10px;
        padding-bottom: 0;
    }

    &__description {
        color: $color--card;

        ul {
            list-style-type: circle;
            padding-left: 15px;
        }
    }

    &__publication-date {
        display: flex;
        align-items: center;
        color: $color-second--card;
        margin: 15px 0 0 0;

        svg {
            flex: none;
            width: 24px;
            height: 24px;
            margin-right: 10px;
            fill: $color-second--card;
        }
    }

    &__location {
        display: flex;
        align-items: center;
        margin-top: 15px;

        p {
            flex: 1;
            color: $color-second--card;
        }

        svg {
            flex: none;
            width: 26px;
            height: 26px;
            fill: $color-second--card;
            margin: 0 6px 0 0;
        }
    }


    //=================================================================================================
    // Image par défaut
    //=================================================================================================
    .image-wrapper {
        background-repeat: no-repeat;
        background-position: center;

        &.post,
        &.category {
            background-image: url($urlShort + '/icon-no-image/icon-post.svg');
        }

        &.page,
        &.transitions, 
        &.pages-systeme {
            background-image: url($urlShort + '/icon-no-image/icon-page.svg');
        }
    }


    //=================================================================================================
    // Squared cards appearance
    //=================================================================================================
    &--square {
        .card {
            &__container {
                align-content: flex-start;
                height: 100%;
            }

            &__image-wrapper {
                width: 100%;
                height: $card-imageH;
                min-height: 0;
            }

            &__content {
                position: initial;
                display: flex;
                flex-direction: column;
                padding: 0 $card-content--padding $card-content--padding $card-content--padding;
            }

            &__title {
                margin: 0 0 10px;
            }

            &__infos {
                justify-content: flex-start;
                transform: translateY(-50%);
            }

            &__actions {
                top: 10px;
                right: 10px;
            }
        }
    }
}


//=================================================================================================
// Flexible ? Slider ?
//=================================================================================================
.card-embed-wrapper {
    margin: $gl-gutter-vertical 0;

    .swiper-container {
        padding: 10px;
        margin: -10px;
    }

    .swiper-pagination {
        position: relative;
        margin-top: 15px;
        bottom: 0;
    }

    .swiper-pagination-bullet,
    .swiper-pagination-bullet-active {
        width: 10px;
        height: 10px;
        background-color: $color-gray;
        border-radius: $border-radius;
        margin: 0 5px;
        transform-origin: 50% 50%;
        opacity: 0.8;
        transition: {
            property: opacity, width;
            duration: $duration;
            timing-function: $timing;
        }

        &:hover, &:focus {
            opacity: 1;
        }
    }

    .swiper-pagination-bullet-active {
        width: 40px;
        opacity: 1;
    }
  }


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .card {
        margin-bottom: 10px;

        &__image-wrapper {
            width: 280px;
        }

    }

}


// 960
@media screen and (max-width: $medium) {

    .card {
        margin-bottom: 0;
    }

}


// 640
@media screen and (max-width: $small) {
    .card  {
        &__image-wrapper {
            width: 270px;
        }

        &__container {
            min-height: auto;

            &--search .card__head-content {
                @include flex($direction: column-reverse, $alignItems: flex-start, $justifyContent: flex-start);
                gap: 10px;

                .card__category {
                    margin-left: 0;
                }
            }
        }

        &__title {
            @include font-size(19);
        }

        &--square {
            .card  {
                &__container  {
                    height: auto;
                }
            }
        }
    }

    .card-embed-wrapper {
        .swiper-container {
            [class*=col-] {
                padding-bottom: 15px;
            }
        }
    }

    .card__content--category {
        align-items: baseline;
        flex-direction: column;

        .card__image-wrapper {
            width: 100%;
            height: 185px;
            min-height: inherit;
        }
        .card__content {
            margin-top: $card-categorie--margin;
            padding: $card-content--padding;
        }
    }

    .card-embed-wrapper .swiper-pagination {
        margin-top: 10px;
    }

}
